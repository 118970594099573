import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Check your email"
        />
        <h1>Please check your email</h1>
        <p>Before continuing, you need to confirm your email right now.</p>
        <p>(It’s super easy)</p>
        <p>Just go your inbox, open the email from <strong>‘Tom from Scalable CSS’</strong>, and click the link.</p>
        <p>And that’s it!</p>
        <p>PS: If you don’t see a confirmation email, <strong>please check your spam/junk folder</strong>. Sometimes the confirmation message ends up there by mistake.</p>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
          }
        }
      }
    }
  }
`
