import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Tom from '../../content/assets/profile-pic.jpg'

import { rhythm } from '../utils/typography'

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
            }}
          > 
            <img src={Tom} alt={author} style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                height: '70px',
                borderRadius: `100%`,
              }}/>            
            <p>
              By <a href="https://twitter.com/scalablecss/" target="blank">Tom Ray</a>, a front-end developer who lives in London with his fiancée and cat <a href="https://twitter.com/scalablecss/status/1261656062073200641?s=20" target="blank">Arnold</a>.
            </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 70, height: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
